import * as React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';

import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import Container from '@mui/material/Container';



import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Collapse from '@mui/material/Collapse';


import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import WebIcon from '@mui/icons-material/Web';
import QuizIcon from '@mui/icons-material/Quiz';


import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';



import UserMenu from 'components/admin/components/UserMenu';
import LanguagesMenu from 'components/languages';
import { useTranslations } from 'next-intl';

//import { mainListItems } from './listItems';


function Footer(props) {
    const t = useTranslations();
    return (
        <div className="footer">
            <div className="footer-container-inner">
                <div className="copy-text">
                    {`© ${new Date().getFullYear()} ${'TimerTone.com'} `}
                </div>
                {/*<div className="social-media">
                    <ul>

                        <li>
                            <Link color="inherit" href="#">
                                <div className="circle twitter-class">
                                    {(<svg width="16.67" height="13.54" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.62 3.60533C15.6306 3.75338 15.6306 3.90145 15.6306 4.0495C15.6306 8.56515 12.1936 13.7682 5.91187 13.7682C3.97658 13.7682 2.17878 13.2077 0.666504 12.2348C0.941472 12.2665 1.20583 12.2771 1.49138 12.2771C3.08823 12.2771 4.55822 11.7378 5.73208 10.8177C4.23039 10.786 2.97192 9.80249 2.53832 8.44884C2.74985 8.48054 2.96134 8.5017 3.18344 8.5017C3.49012 8.5017 3.79682 8.45938 4.08234 8.38539C2.51719 8.06811 1.3433 6.69333 1.3433 5.033V4.99072C1.79802 5.24453 2.32683 5.40315 2.88728 5.42428C1.96723 4.8109 1.36446 3.76395 1.36446 2.57951C1.36446 1.945 1.53363 1.36336 1.82976 0.85574C3.51124 2.9285 6.03876 4.28212 8.87292 4.4302C8.82005 4.17639 8.78831 3.91203 8.78831 3.64765C8.78831 1.76522 10.3112 0.231812 12.2041 0.231812C13.1876 0.231812 14.076 0.644248 14.6999 1.31049C15.4719 1.16245 16.2122 0.876899 16.8679 0.485621C16.614 1.27879 16.0747 1.94503 15.3662 2.36802C16.0536 2.29403 16.7198 2.10363 17.3332 1.83927C16.8679 2.51607 16.2863 3.11883 15.62 3.60533Z" fill="#ffffff" />
                                    </svg>
                                    )}

                                </div>

                            </Link>
                        </li>
                        <li>
                            <Link color="inherit" href="#">
                                <div className="circle whatsup-class">
                                    {(
                                        <svg width="17.5" height="17.5" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.1289 2.79297C13.4922 1.15234 11.3125 0.25 8.99609 0.25C4.21484 0.25 0.324219 4.14062 0.324219 8.92188C0.324219 10.4492 0.722656 11.9414 1.48047 13.2578L0.25 17.75L4.84766 16.543C6.11328 17.2344 7.53906 17.5977 8.99219 17.5977H8.99609C13.7734 17.5977 17.75 13.707 17.75 8.92578C17.75 6.60938 16.7656 4.43359 15.1289 2.79297ZM8.99609 16.1367C7.69922 16.1367 6.42969 15.7891 5.32422 15.1328L5.0625 14.9766L2.33594 15.6914L3.0625 13.0312L2.89062 12.7578C2.16797 11.6094 1.78906 10.2852 1.78906 8.92188C1.78906 4.94922 5.02344 1.71484 9 1.71484C10.9258 1.71484 12.7344 2.46484 14.0938 3.82812C15.4531 5.19141 16.2891 7 16.2852 8.92578C16.2852 12.9023 12.9688 16.1367 8.99609 16.1367ZM12.9492 10.7383C12.7344 10.6289 11.668 10.1055 11.4688 10.0352C11.2695 9.96094 11.125 9.92578 10.9805 10.1445C10.8359 10.3633 10.4219 10.8477 10.293 10.9961C10.168 11.1406 10.0391 11.1602 9.82422 11.0508C8.55078 10.4141 7.71484 9.91406 6.875 8.47266C6.65234 8.08984 7.09766 8.11719 7.51172 7.28906C7.58203 7.14453 7.54687 7.01953 7.49219 6.91016C7.4375 6.80078 7.00391 5.73438 6.82422 5.30078C6.64844 4.87891 6.46875 4.9375 6.33594 4.92969C6.21094 4.92188 6.06641 4.92188 5.92188 4.92188C5.77734 4.92188 5.54297 4.97656 5.34375 5.19141C5.14453 5.41016 4.58594 5.93359 4.58594 7C4.58594 8.06641 5.36328 9.09766 5.46875 9.24219C5.57812 9.38672 6.99609 11.5742 9.17188 12.5156C10.5469 13.1094 11.0859 13.1602 11.7734 13.0586C12.1914 12.9961 13.0547 12.5352 13.2344 12.0273C13.4141 11.5195 13.4141 11.0859 13.3594 10.9961C13.3086 10.8984 13.1641 10.8438 12.9492 10.7383Z" fill="#ffffff" />
                                        </svg>
                                    )}

                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link color="inherit" href="#">
                                <div className="circle facebook-class">
                                    {(
                                        <svg width="8.93" height="16.67" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.87809 10.0416L9.34098 7.02535H6.44678V5.06799C6.44678 4.2428 6.85107 3.43844 8.1473 3.43844H9.46305V0.870402C9.46305 0.870402 8.26904 0.666626 7.12744 0.666626C4.74398 0.666626 3.18603 2.11129 3.18603 4.72652V7.02535H0.536621V10.0416H3.18603V17.3333H6.44678V10.0416H8.87809Z" fill="#ffffff" />
                                        </svg>
                                    )}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link color="inherit" href="#">
                                <div className="circle linkedin-class">
                                    {(
                                        <svg width="16.67" height="16.67" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.39716 17.3332H0.941802V6.20593H4.39716V17.3332ZM2.66762 4.68808C1.56271 4.68808 0.666504 3.7729 0.666504 2.66799C0.666504 2.13726 0.877335 1.62827 1.25262 1.25298C1.6279 0.877701 2.13689 0.66687 2.66762 0.66687C3.19835 0.66687 3.70734 0.877701 4.08262 1.25298C4.45791 1.62827 4.66874 2.13726 4.66874 2.66799C4.66874 3.7729 3.77216 4.68808 2.66762 4.68808ZM17.3295 17.3332H13.8815V11.9165C13.8815 10.6256 13.8555 8.97007 12.085 8.97007C10.2885 8.97007 10.0132 10.3726 10.0132 11.8235V17.3332H6.56159V6.20593H9.87558V7.72379H9.92395C10.3853 6.84953 11.5121 5.92691 13.1933 5.92691C16.6903 5.92691 17.3332 8.22974 17.3332 11.2208V17.3332H17.3295Z" fill="#ffffff" />
                                        </svg>

                                    )}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Embed color="white" />

                        </li>
                    </ul>
                                    </div>*/}

            </div>

        </div>

    );
}

const drawerWidth = 315;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



const mdTheme = createTheme();



function LayoutContent({ children, raw_url, lang_key, subdomain }) {
    const user = useRecoilValue(userState);
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const t = useTranslations();


    const router = useRouter();
    const asPath = router.asPath;
    const url_path = `//${raw_url}${asPath}`;









    const clock_sub = [
        {
            path: "/clock/alarm",
            full_path: `//${lang_key}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/alarm`,
            label: t("Set Alarm"),
            icon: (<AccessAlarmIcon />),
            active: false
        }, {
            path: "/clock/stopwatch",
            full_path: `//${lang_key}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/stopwatch`,
            label: t("Stopwatch"),
            icon: (<TimerOutlinedIcon />),
            active: false
        }, {
            path: "/clock/timer",
            full_path: `//${lang_key}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/timer`,
            label: t("Set timer "),
            icon: (<AvTimerOutlinedIcon />),
            active: false
        }, {
            path: "/clock/countdown",
            full_path: `//${lang_key}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/countdown`,
            label: t("Countdown"),
            icon: (<HourglassEmptyOutlinedIcon />),
            active: false
        }
        /*, {
            path: "/clock/current-time",
            full_path: `//current-time.${lang_key}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/`,
            label: t("Current time"),
            icon: (<AccessTimeOutlinedIcon />),
            active: false
        }*/
    ];

    const manager_sub = [
        {
            path: "/moderator/translations",
            label: "Translations",
            icon: <TranslateIcon />,
            active: false
        }, {
            path: "/moderator/languages",
            label: "Languages",
            icon: <LanguageIcon />,
            active: false
        }, {
            path: "/moderator/pages",
            label: "Pages",
            icon: <WebIcon />,
            active: false
        }, {
            path: "/moderator/faq",
            label: "Faq",
            icon: <QuizIcon />,
            active: false
        }
    ];
    const found = clock_sub.find(v => v.path === url_path);
    const foundmanager = manager_sub.find(v => v.path === url_path);
    const force_collapse = found !== undefined ? true : false;
    const force_collapse_manager = foundmanager !== undefined ? true : false;
    const _collapsed = force_collapse ? true : false;
    const _collapsedmanager = force_collapse_manager ? true : false;
    const [collapsed, setCollapsed] = React.useState(_collapsed);
    const [collapsedmanager, setCollapsedmanager] = React.useState(_collapsedmanager);


    const drawer = (
        <List component="nav">

            {clock_sub.map((v, i) => {
                return (
                    <ListItemButton key={i} className={url_path === v.full_path ? 'nav_active' : ''} component="a" href={v.full_path} >
                        <ListItemIcon>
                            {v.icon}
                        </ListItemIcon>
                        <ListItemText primary={v.label} />
                    </ListItemButton>
                );
            })}



            {user && <>
                <Divider sx={{ my: 1 }} />
                <ListItemButton component="a" onClick={() => { setCollapsedmanager(!collapsedmanager) }} >
                    <ListItemIcon>
                        <AccessTimeIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Manager" />
                    {(collapsedmanager) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={collapsedmanager} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {manager_sub.map((v, i) => {
                            return (
                                <ListItemButton key={i} className={url_path === v.path ? 'nav_active' : ''} sx={{ pl: 4 }} component="a" href={v.path} >
                                    <ListItemIcon>
                                        {v.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={v.label} />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Collapse>
            </>}

        </List>
    );


    const handleClick = () => {
        setCollapsed(!collapsed);
    };




    return (
        <div >
            <Head>

            </Head>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBar className="top-header" position="fixed" open={true}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <div className='mobile-logo'>
                                <Link color="inherit" aria-label="TimerTone link" className="logo-div" href="/">
                                    <h3 className="title">TimerTone</h3>
                                </Link>
                            </div>
                            <div className="social-media">
                                <ul>

                                    <li>
                                        <Link aria-label="twitter" color="inherit" href="#">
                                            <div className="circle twitter-class">
                                                {(<svg width="16.67" height="13.54" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.62 3.60533C15.6306 3.75338 15.6306 3.90145 15.6306 4.0495C15.6306 8.56515 12.1936 13.7682 5.91187 13.7682C3.97658 13.7682 2.17878 13.2077 0.666504 12.2348C0.941472 12.2665 1.20583 12.2771 1.49138 12.2771C3.08823 12.2771 4.55822 11.7378 5.73208 10.8177C4.23039 10.786 2.97192 9.80249 2.53832 8.44884C2.74985 8.48054 2.96134 8.5017 3.18344 8.5017C3.49012 8.5017 3.79682 8.45938 4.08234 8.38539C2.51719 8.06811 1.3433 6.69333 1.3433 5.033V4.99072C1.79802 5.24453 2.32683 5.40315 2.88728 5.42428C1.96723 4.8109 1.36446 3.76395 1.36446 2.57951C1.36446 1.945 1.53363 1.36336 1.82976 0.85574C3.51124 2.9285 6.03876 4.28212 8.87292 4.4302C8.82005 4.17639 8.78831 3.91203 8.78831 3.64765C8.78831 1.76522 10.3112 0.231812 12.2041 0.231812C13.1876 0.231812 14.076 0.644248 14.6999 1.31049C15.4719 1.16245 16.2122 0.876899 16.8679 0.485621C16.614 1.27879 16.0747 1.94503 15.3662 2.36802C16.0536 2.29403 16.7198 2.10363 17.3332 1.83927C16.8679 2.51607 16.2863 3.11883 15.62 3.60533Z" fill="#444D67" />
                                                </svg>
                                                )}
                                            </div>

                                        </Link>
                                    </li>
                                    <li>
                                        <Link aria-label="whatsup" color="inherit" href="#">
                                            <div className="circle whatsup-class">
                                                {(
                                                    <svg width="17.5" height="17.5" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.1289 2.79297C13.4922 1.15234 11.3125 0.25 8.99609 0.25C4.21484 0.25 0.324219 4.14062 0.324219 8.92188C0.324219 10.4492 0.722656 11.9414 1.48047 13.2578L0.25 17.75L4.84766 16.543C6.11328 17.2344 7.53906 17.5977 8.99219 17.5977H8.99609C13.7734 17.5977 17.75 13.707 17.75 8.92578C17.75 6.60938 16.7656 4.43359 15.1289 2.79297ZM8.99609 16.1367C7.69922 16.1367 6.42969 15.7891 5.32422 15.1328L5.0625 14.9766L2.33594 15.6914L3.0625 13.0312L2.89062 12.7578C2.16797 11.6094 1.78906 10.2852 1.78906 8.92188C1.78906 4.94922 5.02344 1.71484 9 1.71484C10.9258 1.71484 12.7344 2.46484 14.0938 3.82812C15.4531 5.19141 16.2891 7 16.2852 8.92578C16.2852 12.9023 12.9688 16.1367 8.99609 16.1367ZM12.9492 10.7383C12.7344 10.6289 11.668 10.1055 11.4688 10.0352C11.2695 9.96094 11.125 9.92578 10.9805 10.1445C10.8359 10.3633 10.4219 10.8477 10.293 10.9961C10.168 11.1406 10.0391 11.1602 9.82422 11.0508C8.55078 10.4141 7.71484 9.91406 6.875 8.47266C6.65234 8.08984 7.09766 8.11719 7.51172 7.28906C7.58203 7.14453 7.54687 7.01953 7.49219 6.91016C7.4375 6.80078 7.00391 5.73438 6.82422 5.30078C6.64844 4.87891 6.46875 4.9375 6.33594 4.92969C6.21094 4.92188 6.06641 4.92188 5.92188 4.92188C5.77734 4.92188 5.54297 4.97656 5.34375 5.19141C5.14453 5.41016 4.58594 5.93359 4.58594 7C4.58594 8.06641 5.36328 9.09766 5.46875 9.24219C5.57812 9.38672 6.99609 11.5742 9.17188 12.5156C10.5469 13.1094 11.0859 13.1602 11.7734 13.0586C12.1914 12.9961 13.0547 12.5352 13.2344 12.0273C13.4141 11.5195 13.4141 11.0859 13.3594 10.9961C13.3086 10.8984 13.1641 10.8438 12.9492 10.7383Z" fill="#444D67" />
                                                    </svg>
                                                )}
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link color="inherit" aria-label="facebook" href="#">
                                            <div className="circle facebook-class">
                                                {(
                                                    <svg width="8.93" height="16.67" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.87809 10.0416L9.34098 7.02535H6.44678V5.06799C6.44678 4.2428 6.85107 3.43844 8.1473 3.43844H9.46305V0.870402C9.46305 0.870402 8.26904 0.666626 7.12744 0.666626C4.74398 0.666626 3.18603 2.11129 3.18603 4.72652V7.02535H0.536621V10.0416H3.18603V17.3333H6.44678V10.0416H8.87809Z" fill="#444D67" />
                                                    </svg>
                                                )}
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link color="inherit" aria-label="linkedin" href="#">
                                            <div className="circle linkedin-class">

                                                {(
                                                    <svg width="16.67" height="16.67" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.39716 17.3332H0.941802V6.20593H4.39716V17.3332ZM2.66762 4.68808C1.56271 4.68808 0.666504 3.7729 0.666504 2.66799C0.666504 2.13726 0.877335 1.62827 1.25262 1.25298C1.6279 0.877701 2.13689 0.66687 2.66762 0.66687C3.19835 0.66687 3.70734 0.877701 4.08262 1.25298C4.45791 1.62827 4.66874 2.13726 4.66874 2.66799C4.66874 3.7729 3.77216 4.68808 2.66762 4.68808ZM17.3295 17.3332H13.8815V11.9165C13.8815 10.6256 13.8555 8.97007 12.085 8.97007C10.2885 8.97007 10.0132 10.3726 10.0132 11.8235V17.3332H6.56159V6.20593H9.87558V7.72379H9.92395C10.3853 6.84953 11.5121 5.92691 13.1933 5.92691C16.6903 5.92691 17.3332 8.22974 17.3332 11.2208V17.3332H17.3295Z" fill="#444D67" />
                                                    </svg>

                                                )}
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{ mr: 2, display: { sm: 'none' } }}

                            >
                                <MenuIcon />
                            </IconButton>

                            {user && <UserMenu />}
                            {!user && <LanguagesMenu subdomain={subdomain} raw_url={raw_url} lang_code={lang_key} />}

                        </Toolbar>
                    </AppBar>
                    <Drawer className="left-menu"
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        variant="temporary" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >

                        </Toolbar>
                        {drawer}
                    </Drawer>
                    <Drawer
                        className="left-menu"
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        <Link color="inherit" className="logo-div" href="/">
                            <h3 className="title">TimerTone</h3>
                        </Link>
                        {drawer}
                    </Drawer>
                    <Box
                        component="main"
                        className="main-component"
                        sx={{ flexGrow: 1, p: 3, marginLeft: { sm: `${drawerWidth}px` }, padding: "0", paddingLeft: "30px", paddingRight: "30px" }}

                    >
                        <Toolbar />
                        <Container className="main-div" maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                            {children}
                        </Container>
                        <Footer />
                    </Box>

                </Box>

            </ThemeProvider>
        </div>
    );
}

export default function Layout({ children, ...props }) {

    return (<LayoutContent {...props} >
        {children}
    </LayoutContent >);
}