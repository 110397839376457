import * as React from 'react';



import Image from 'next/image';
import MenuItem from '@mui/material/MenuItem';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { languagesState, translationsState } from 'store/atoms';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';






const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

export default function LanguagesMenu({ raw_url, lang_code, subdomain }) {

    const languages = useRecoilValue(languagesState);

    const translations = useRecoilValue(translationsState);
    const setTranslations = useSetRecoilState(translationsState);
    const initial_code = lang_code ? lang_code : defaultLocale;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedCode, setSelectedCode] = React.useState(initial_code);
    const [selectedEmbedSize, setSelectedEmbedSize] = React.useState(0);
    const [openEmbed, setOpenEmbed] = React.useState(false);
    const siteUrl = `${process.env.NEXT_PUBLIC_SITE_URL}clock/embed`;
    const [iiframe, setIiframe] = React.useState(`<iframe width="100%" height="100%" src=${siteUrl} frameborder="0" allowfullscreen></iframe>`);
    const open = Boolean(anchorEl);




    //const default_image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOrSURBVHgB7ZrbShtRFIaXOZmYeEhiNOobCEIt9CV6I32OFi9FxYv0DUR644v0AUQvvNDbCiLmoqitimLOx+n+d4hkjAYUJzs7/T8cnOSfFTJ77fn3WjMRIYQQQgghhBBCCCGEEEIIIWSYGXG//OGIZTjyTWxjpGPcA09Fv9+nt1hsTKrVmlQqNanV6o96MBiQ0dGghEJByeWK0mw2pdFoPsb6fD4ZH/c2Np8v6rh2rM34nr6BgcBJJhITEo1G9H4neI33oWMfg/b4Yb7+xeJ7DgNdZ4GTnJ2Ny+npimxsLKv9pEufnp6S9fUPWoeGK6XN2FhY617Hzs+n1JUSlWGgy4Kq1bq6xEuyvf1L9vevpFAoSjgcVBYQlEAgoO3h4OCPPhYabAR6JBJRul/rXsc+PBS0RWkyGbGOju/ctQjDa3GJp1JxKRZLaiurAfCpQQqrWRqR+/u81uHHNzd3yjpG9JZMxqVcruoNPu5l7PX1nU4I1gjH+Sq2MaJo73dZEE6qUChJNnsht7f36nVVFheTsrW1LMfHKzI5GVMDVdE6tEQipHVoq6tLWvc6Ft+vc4G2mWerICyIWAsajbrU6w0144qytwdbcPQshV1MTY0rG6hIqdTSd3dP5PDwr9aheRmLBHRWUDZDCzJATwuKxSKSTsfl/PyLbG5+lJmZpDr5glxe3srZ2W+tr60taR2a3x/QOjTU59C9jl1YSMnERLR9NvZtHdhfBVmOOx02WpDltyJeVQUdHb1cyUDrVcm8Z+x/WwXl82+vZN4z1lUFsRFjFfRahqsKshxWQYahBRngzfeCBrIKsqkBe6YR87uH/3MGVRDsAB6LGeioh5T4X62KXFyUlQ1cCh4EYjbCQnB8o+Go4wK6kslmH6T1Gd7FovrBeTjqoIz8FNv4rv9auNPBRqwv9GzEWAX1Fz4RMwEbMbMMVxVkOfbfC7IcWpAB+ERskJ+IYVahCtrZOdHVCAamE8xMvI+ZCw0zsTMWutexudwQ3wsKhVoWlE4n9e83kQx4bhusDZiNsArMzrYVgLZ9zc15G3t11YpFyWx7I9aVALGMofp1dCbzSewjI9bxUiPm4O4W8ZyeVRDpL0yAYZgAw7j7gCdNAvEeXgGGYQIMwwQYxr0G2Ph0yUbYiJmFjdgAwQQYhgkwDBsxw/AKMAwTYBgmwDBsxEzARswsbMQGCCbAMEyAYdiIGYZXgGGYAMMwAYZhAgzDBBBCCCGEEEIIIYQQQgghhBBCPOQfE3pcnqR4Sa0AAAAASUVORK5CYII=';


    const handleMenuItemClick = (event) => {
        const code = event.target.value;

        setSelectedCode(code);
        // setLocale(code);
        setTranslations({
            ...translations,
            current: code
        })
        setAnchorEl(null);
        setTimeout(() => {

            window.location = `//${code}.timertone.${process.env.NEXT_PUBLIC_DOMAIN_SUFFIX}/dashboard/${subdomain}`;
        }, 0);


    };

    const handleClose = () => {
        setOpenEmbed(false)
    };
    const openEmbedDialog = () => {
        setOpenEmbed(true)
    }

    return (
        <div className="languages" style={{
            display: "flex",
            alignItems: "center"
        }} >

            <FormControl variant='outlined' >

                <Select
                    MenuProps={{ classes: { paper: "custom-MuiMenu-list" } }}
                    className='languages-select'
                    width={"500px"}
                    value={selectedCode}
                    onChange={handleMenuItemClick}
                    displayEmpty
                    inputProps={{ 'aria-label': 'no-border' }}
                >
                    {languages.entities && languages.entities.map((language, index) => (
                        <MenuItem
                            className="flex-div left-align"
                            value={language.code}
                            key={language.id}
                            selected={language.code === selectedCode}

                        >
                            {language.image && <div className="image-div" style={{ height: "24px" }}>
                                <Image width={"24px"} height={"24px"} alt={'country flag'} src={language.image} />
                            </div>}

                            {language.display_name && <div className='text-language-div' style={{ marginLeft: "6px", marginTop: "-0.5px" }}> {language.display_name}</div>}

                        </MenuItem>
                    ))}
                </Select>

            </FormControl>
            {/* <Embed />*/}
        </div >
    );
}